* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

:root {
  --hl: #555;
  --bg: #111;
  --text: #eee;
}

.App {
  background-color: var(--bg);
  color: var(--text);
  min-height: 100vh;
}
section {
  min-height: 100vh;
}
#hero {
  position: relative;
  height: 100vh;
}

#hero-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
  object-fit: cover;
  z-index: 1;
}
.flex {
  display: flex;
}
.justifyCenter {
  justify-content: center;
}
.alignCenter {
  align-items: center;
}

.centerAll {
  display: flex;
  align-items: center;
  justify-content: center;
}

#site-title {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  background-color: #111;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#site-title a {
  font-size: 1.5rem;
  color: white;
  margin-right: 6rem;
  height: 1.5rem;
}

#site-title h1 {
  font-size: 2rem;
  font-style: italic;
  text-shadow: 2px 2px var(--hl);
}
#site-title h2 {
  font-size: 1.5rem;
  font-weight: 400;
}

nav {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
}

#nav {
  list-style: none;
  position: absolute;
  top: 3rem;
  left: 0;
  transform: translateX(100%);
  width: 100vw;
  background-color: #111;
  transition: 300ms transform;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#nav.showing {
  transform: translateX(0);
}

#nav li {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
#nav li:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
#nav button {
  font-size: 2rem;
  padding: 0 1rem;
  background-color: transparent;
  border: none;
  color: var(--text);
  height: 100%;
  width: 100%;
  cursor: pointer;
}

#hamburger {
  background-color: transparent;
  border: none;
}

.hamburger {
  width: 2rem;
  height: 2px;
  margin: 0.5rem 0;
  background-color: #eee;
}

.info {
  padding: 2rem;
  max-width: 1440px;
  margin: 0 auto;
  line-height: 2.8rem;
  font-size: 2rem;
  position: relative;
  transition: 1000ms transform;
  transform: translateY(8rem);
  opacity: 0;
}

.info.showing {
  transform: translateY(0);
  opacity: 1;
}

.info:hover::before {
  right: 25%;
}
.info:hover::after {
  left: 25%;
}

.info::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 75%;
  height: 4px;
  background-color: var(--hl);
  transition: 500ms all;
}
.info::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 75%;
  height: 4px;
  background-color: var(--hl);
  transition: 500ms all;
}

#video {
  max-width: 1024px;
  margin: 0 auto;
}

#video-list {
  list-style: none;
  padding: 1rem 0;
}

.video-list__item {
  padding: 1.5rem 2rem;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  transition: 500ms all;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--hl);
}
.video-list__item:hover {
  background-color: #333;
}
.video-list__item:last-of-type {
  border-bottom: none;
}
.video-list__item span {
  margin: 0 1rem;
}
.video-list__item.selected {
  background-color: #999;
  color: #000;
}

@media (max-width: 720px) {
  .info {
    font-size: 1rem;
    line-height: 1.4rem;
  }
}
